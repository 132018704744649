import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TextField, Button, CircularProgress, Typography, InputLabel } from '@material-ui/core'
import { toast } from 'react-toastify'
import { withStyles } from '@material-ui/core/styles'
import { successLogin, changePassword } from 'redux/auth/auth.actions'
import { Link } from 'react-router-dom'
import { login } from 'services/apiAuth'
import jwtDecode from 'jwt-decode'
import Recaptcha from 'react-recaptcha'
import styles from './styles'

class Login extends Component {
  state = {
    email: '',
    password: '',
    loading: false,
    token: this.props.auth.access_token,
    isVerified: false,
    recaptchaToken: null,
    error: null,
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  componentDidMount() {
    this.isLogged(this.state.token)
  }

  isLogged = token => {
    if (token) {
      const decoded = jwtDecode(token)
      const current_time = Date.now() / 1000
      if (decoded.exp > current_time) {
        return this.props.history.push({
          pathname: '/dashboard',
        })
      }
      return false
    }
    return false
  }

  verifyCaptcha = response => {
    if (response) {
      this.setState({
        isVerified: true,
        recaptchaToken: response,
      })
    }
  }

  handleSubmit = async () => {
    const { email, password, recaptchaToken } = this.state
    try {
      this.setState({ loading: true })
      const formData = new FormData()
      formData.append('username', email)
      formData.append('password', password)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('recaptcha_token', recaptchaToken)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)

      const res = await login(formData)
      if (res.status === 302) {
        this.props.changePassword({
          email: email,
          changePasswordSession: res.data.session,
        })
        return this.props.history.push({
          pathname: '/change-password',
        })
      }

      if (res.status === 200) {
        const userdata = res.data
        if (userdata) {
          toast.success(`Bem vindo de volta ${userdata.name}`, {
            position: toast.POSITION.TOP_RIGHT,
          })
          this.props.successLogin({
            ...userdata,
          })
          return this.props.history.push({
            pathname: '/quiz',
          })
        }
      }
      return false
    } catch (error) {
      this.setState({ loading: false })
      if (error.response) {
        if (error.response.status === 422) {
          toast.error('Usuário ou senha inválidos')
        }
        this.setState({
          loading: false,
          isVerified: false,
          error: error.response.data,
        })
      }
      return false
    }
  }

  onEnterPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      return this.handleSubmit(this.state.email, this.state.password)
    }
    return false
  }

  render() {
    const { classes } = this.props
    const { loading, email, password, isVerified, error } = this.state
    return (
      <div className={classes.root}>
        <aside className={classes.aside}>
          <h1>
            <img src="assets/images/logos/logo.svg" alt="Logo" className={classes.logo} />
          </h1>
        </aside>
        <main className={classes.main}>
          <div className={classes.main}>
            <h1 style={{ fontSize: 30, color: '#b9e600' }}>Login</h1>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <form>
                <InputLabel htmlFor="email" style={{ fontStyle:'bold', color: '#fff' }}>E-mail</InputLabel>
                <TextField
                  id="email"
                  className={classes.textField}
                  value={email}
                  onChange={this.handleChange('email')}
                  variant="outlined"
                />
                <InputLabel htmlFor="password" style={{ fontStyle:'bold', color: '#fff' }}>Senha</InputLabel>
                <TextField
                  id="password"
                  className={classes.textField}
                  value={password}
                  onChange={this.handleChange('password')}
                  type="password"
                  onKeyDown={this.onEnterPress}
                  autoComplete="off"
                  variant="outlined"
                />
              </form>
              <Typography style={{ width: '300px', display: 'block', color:'#fff' }}>
                Já esqueci a senha.
                <Link to="/recover-password" style={{ color: '#b9e600'}}>Socorro!</Link>
              </Typography>
              {error && error.msg_errors ? (
                <p>
                  {error.msg_errors.map(item => (
                    <strong style={{ color: '#f2545b', fontSize: 16 }} key={item.msg}>
                      {item.msg}
                    </strong>
                  ))}
                </p>
              ) : null}
            </div>

            <div style={{ textAlign: 'center', width: 300 }}>
              {!isVerified ? (
                <Recaptcha
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  render="explicit"
                  onloadCallback={() => {}}
                  verifyCallback={this.verifyCaptcha}
                  hl="pt-BR"
                />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{
                        width: 25,
                        height: 25,
                      }}
                    />
                  ) : (
                    'Acessar'
                  )}
                </Button>
              )}
            </div>
          </div>
        </main>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      successLogin,
      changePassword,
    },
    dispatch
  )

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Login))
