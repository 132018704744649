import axios from './axiosCustom'

const HOST = `${process.env.REACT_APP_AUTHENTICATOR_API}/v2`
const AUTH = process.env.REACT_APP_AUTHENTICATOR_AUTH

export async function login(data) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}/users/login`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
    // eslint-disable-next-line func-names
    validateStatus: function(status) {
      return status >= 200 && status <= 302
    },
  })
  return res
}

export async function register(data) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}/users/signup`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/json',
    },
    data,
  })
  return res
}

export async function recoverPasswd(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}/passwords/forgot`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })
  return res
}

export async function changePassword(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}/passwords/change`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/json',
    },
    data: formData,
  })
  return res
}

export async function confirmEmail(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}/users/confirm`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })
  return res
}

export async function confirmRecoverPassword(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}/passwords/forgot/confirm`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })
  return res
}
