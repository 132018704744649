import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#B9E600',
      hover: '#232d28',
      bg: '#FFF',
      dark: '#192131',
      darker: '#070F20',
      light: '#9B9FB0',
      lighter: '#FFF',
    },
    secondary: {
      main: '#627026',
      hover: '#64665e',
    },
    third: {
      main: '#b9e600',
      hover: '#019101',
    },
    error: {
      main: '#f2545b',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
})

export default theme
