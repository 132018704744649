import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  AppBar,
  Toolbar,
  Popper,
  Grow,
  Paper,
  Typography,
  // InputBase,
  MenuItem,
  MenuList,
  ClickAwayListener,
  useMediaQuery,
  IconButton,
  Drawer,
  Button
} from '@material-ui/core'

import {
  Assessment,
  CheckBox,
  Settings,
  Menu as MenuIcon,
  // Search as SearchIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'
import { logout } from 'redux/auth/auth.actions'

import styles from './styles'

const MainNavbar = ({ groups }) => {
  const classes = styles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const dispatch = useDispatch()
  const theme = useTheme()

  function handleToggle() {
    setOpen(prevOpen => !prevOpen)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const [openDrawer, setOpenDrawer] = React.useState(false)
  const name = useSelector(state => state.auth.name)


  function handleDrawerOpen() {
    setOpenDrawer(true)
  }

  function handleDrawerClose() {
    setOpenDrawer(false)
  }

  function handleClose(event) {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return
    }

    setOpen(false)
  }

  const menuItens = () => {
    const admin = groups.indexOf('Admin')
    return (
      <ul className={classes.listNav}>
        <li className={classes.listNavItem}>

          <Link className={classes.menuItem} to="/quiz">
            <CheckBox />
            Quiz
          </Link>

        </li>

        {admin !== - 1 && (
          <>
            <li className={classes.listNavItem}>

              <Link
                className={classes.menuItem}
                to="/configuration"
              >
                <Settings />
                Configuration
              </Link>

            </li>

            <li className={classes.listNavItem}>

              <Link
                className={classes.menuItem}
                to="/dashboard"
              >
                <Assessment />
                Dashboard
              </Link>

            </li>
          </>
        )}
      </ul>
    )
  }

  const sectionUser = () => (
    <div>
    <Button
      ref={anchorRef}
      aria-controls={open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
      style={{ color: '#fff' }}
    >
      {name.toUpperCase()}
    </Button>
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                <MenuItem 
                  onClick={()=> dispatch(logout())}
                  style={{ with: 300}}
                >
                  Sair
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </div>
  )

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.appbar}
      >
        <Toolbar className={classes.toolbarWrapper}>
          <div
            className={classes.toolbar}
          >
            <div className={classes.sectionLogo}>
              {useMediaQuery('(max-width: 1170px)') && (
                <IconButton
                  color="inherit"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <div className={classes.logoContainer}>
                <img
                  className={classes.logoImg}
                  src="assets/images/logos/logo.svg"
                  alt="logo"
                />
                <Typography
                  style={{ marginLeft: '10px' }}
                  variant="h6"
                  color="#fff"
                >
                  Painel Quiz
                </Typography>
              </div>
            </div>

            <div
              className={classes.menubar}
            >
              <div className={classes.sectionDesktop}>
                {useMediaQuery('(min-width: 1170px)') && menuItens()}
              </div>

              {/* <div className={classes.search}>
                <InputBase
                  placeholder="Buscar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
              </div> */}
              {useMediaQuery('(min-width: 1170px)') && sectionUser()}

            </div>
          </div>
        </Toolbar>
        <Drawer
          className={classes.drawer}
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          {useMediaQuery('(max-width: 1170px)') && menuItens()}
          {useMediaQuery('(max-width: 1170px)') && sectionUser()}
        </Drawer>
      </AppBar>
    </div>
  )
}

MainNavbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

export default MainNavbar
