import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },
  appbar: {
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
    backgroundColor: '#161c19',
  },
  toolbarWrapper: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.34)',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '@media(max-width: 1170px)': {
      padding: 10,
      flexWrap: 'wrap',
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionLogo: {
    display: 'flex',
    marginRight: '4rem',
    alignItems: 'center',
    '@media(max-width: 1170px)': {
      marginBottom: 10,
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#ffffff',
  },
  logoImg: {
    width: 130,
    height: 35,
  },
  menubar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@media(max-width: 1170px)': {
      flexWrap: 'wrap',
      width: '100%',
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  sectionUser: {
    display: 'flex',
    minHeight: 'inherit',
    '@media(max-width: 1170px)': {
      marginTop: 30,
      paddingTop: 15,
      borderTop: '1px solid #CECECE',
    },
  },
  listNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width: 1170px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 240,

    },
  },
  listNavItem: {
    height: 50,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '@media(max-width: 1170px)': {
      padding: 10,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
    },
  },
  listNavItemUsername: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    '@media(max-width: 1170px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
    },
  },
  listNavItemSep: {
    marginLeft: 10,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#ffffff',
    margin: '0 5px',
    height: 50,
    width: '100%',
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      cursor: 'pointer',
    },
    '@media(max-width: 1170px)': {
      color: '#333',
      padding: 10,
      '&:hover': {
        backgroundColor: '#cecece',
      },

    },
  },
  logolf: {
    display: 'none',
    '@media(max-width: 1170px)': {
      color: '#333',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      marginRight: 10,
      marginLeft: 5,
    },
  },
  menuIcon: {
    marginLeft: 10,
  },
  dropdown: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.lighter,
  },
  search: {
    position: 'relative',
    borderRadius: 13,
    backgroundColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    color: '#6d7278',
    '@media(max-width: 1170px)': {
      marginBottom: 10,
      marginRight: 'auto',
    },
  },
  searchIcon: {
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    right: 10,
    top: 7,
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: '#6d7278',
    width: 250,
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  sectionDesktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}))
