import _ from 'lodash'

// import { reqS3PresignerUrl } from 'services/apiFilesDownloader'

function mergeCustomizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return _.union(objValue, srcValue)
  }
}

export const interssectObjects = (obj1, obj2) => {
  const obj2Keys = Object.keys(obj2)
  return Object.fromEntries( Object.entries(obj1).filter(([key, _val]) => obj2Keys.includes(key)) )
}

export const mergeComplex = (obj1, obj2) => {
  return _.mergeWith(obj1, obj2, mergeCustomizer)
}

export const pickByDeep = (obj, predicate) => {
  return Object.entries(obj).reduce((result, [key, val]) => {
    const newResult = result
    let newVal = val
    if (_.isPlainObject(newVal)) {
      newVal = pickByDeep(newVal, predicate)
    }
    if (predicate(newVal)) {
      newResult[key] = newVal
    }

    return newResult
  }, {})
}

// export const s3PresignerUrlOpen = async doc_url => {
//   if (!doc_url) return

  // try {
//     const response = await reqS3PresignerUrl(doc_url)

//     window.open(response.data.url, "_blank")
//   } catch (err) {
//     if (err.response) {
//       console.error(err.response)
//     } else if (err.request) {
//       console.error(err.request)
//     } else {
//       console.error(err.message)
//     }
//   }
// }

export const inputsValid = (inputs) => {
  if (_.isEmpty(inputs)) return false

  return Object.values(inputs).every((input) => {
    if (_.isEmpty(input.properties)) return input.valid === true

    return Object.values(input.properties).every((input) => input.valid === true)
  })
}

export const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response
}
