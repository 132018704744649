export default (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'col',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    sidebar: {
        backgroundColor: '#161c19',
        height: '100%',
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 40,
        color: '#FFF',
    },
    main: {
        height: '100%',
        width: '60%',
        padding: '60px 100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#232d28',
    },
    textField: {
        width: 400,
        marginBottom: 30,
    },
    button: {
        width: 300,
        height: '50px',
        borderRadius: 30,
        marginTop: '50px',
        marginBottom: 20,
        '&:hover':{
            color: '#FFF',
            backgroundColor: theme.palette.primary.hover,
            borderColor: '#b9e600',
            border: '3px solid',
          },
    },
})
