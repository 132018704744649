import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Button, CircularProgress, Typography, InputLabel } from '@material-ui/core'
import { MaskInput } from 'components/MaskedInput'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { successLogin, failChangePassword } from 'redux/auth/auth.actions'
import { Link } from 'react-router-dom'
import { changePassword } from 'services/apiAuth'
import onlyNumbers from 'helpers/onlyNumbers'
import Recaptcha from 'react-recaptcha'
import { toast } from 'react-toastify'
import styles from './styles'

class Index extends React.PureComponent {
  state = {
    isInitialLogin: false,
    password: '',
    confirmPassword: '',
    name: '',
    phoneNumber: '',
    loading: false,
    error: false,
    isVerified: false,
    recaptchaToken: null,
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')

  verifyCaptcha = response => {
    if (response) {
      this.setState({
        isVerified: true,
        recaptchaToken: response,
      })
    }
  }

  handleSubmit = async () => {
    await this.setState({ loading: true })
    try {
      const data = {
        app_id: process.env.REACT_APP_COGNITO_APP_ID,
        user_pool: process.env.REACT_APP_COGNITO_USER_POOL,
        username_email: this.props.authsReducer.email,
        password: this.state.password,
        user_attributes: {
          name: this.state.name,
          phone_number: `+${onlyNumbers(this.state.phoneNumber)}`,
        },
        recaptcha_token: this.state.recaptchaToken,
        session: this.props.authsReducer.changePasswordSession,
      }

      const res = await changePassword(data)
      if (res && res.status === 200) {
        this.setState({ loading: false, isInitialLogin: true })
        const userdata = res.data
        this.props.successLogin(userdata)
        const idInterval = setInterval(() => {
          clearInterval(idInterval)
          this.setState({ isInitialLogin: false })
          this.props.history.push({
            pathname: '/dashboard',
          })
        }, 5000)
      }
    } catch (err) {
      this.props.failChangePassword()
      if (err.response) {
        if (err.response.status === 400) {
          toast.error('Sua sessão expirou. Faça o login novamente!')
          this.props.history.push({
            pathname: '/',
          })
        }
      }
      this.setState({
        loading: false,
        error: true,
        isVerified: false,
        recaptchaToken: null,
      })
    }
  }

  render() {
    const { classes } = this.props
    const { password, name, confirmPassword, loading, error, isVerified, isInitialLogin } = this.state

    if (isInitialLogin)
      return (
        <div className={classes.root} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
          <CircularProgress
            className={classes.progress}
            style={{
              width: 25,
              height: 25,
            }}
          />
          <Typography>Estamos inicializando seus dados...</Typography>
        </div>
      )

    return (
      <div className={classes.root}>
        <div className={classes.sidebar}>
          <img src="assets/images/logos/logo.svg" alt="Logo" style={{ width: 450 }} />
          <div style={{ marginBottom: 50 }}>
            <h1 style={{ fontSize: 50 }}>Redefinir Senha</h1>
            <p style={{ fontSize: 18 }}>Redefina sua senha para acessar a plataforma!</p>
          </div>
        </div>
        <div className={classes.main}>
          <h1 style={{ fontSize: 30, color: '#b9e600' }}>Redefinir Senha</h1>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <InputLabel htmlFor="name" style={{ marginBottom: 10, color: '#fff' }}>
              Nome Completo
            </InputLabel>
            <TextField
              id="name"
              className={classes.textField}
              value={this.state.name}
              onChange={this.handleChange('name')}
              error={!!(name.length !== 0 && name.length < 2)}
              variant='outlined'
            />
            <InputLabel htmlFor="phone_number" style={{ marginBottom: 10, color: '#fff' }}>
              Telefone
            </InputLabel>
            <MaskInput
              className={classes.textField}
              autoComplete="tel"
              value={this.state.phoneNumber}
              onChange={this.handleChange('phoneNumber')}
              name="phone_number"
              variant='outlined'
            />
            <InputLabel htmlFor="password" style={{ marginBottom: 10, color: '#fff' }}>
              Senha
            </InputLabel>
            <TextField
              id="password"
              autoComplete="new-password"
              variant='outlined'
              className={classes.textField}
              value={this.state.password}
              onChange={this.handleChange('password')}
              type="password"
              error={password.length !== 0 ? !this.strongRegex.test(password) : false}
              helperText={
                password.length !== 0
                  ? this.strongRegex.test(password)
                    ? false
                    : 'A senha precisa ter pelo menos 1 letra maiuscula, 1 minuscula, 1 número e um caracter especial, e no mínimo de 8 caracteres.'
                  : false
              }
            />
            <InputLabel htmlFor="confirmPassword" style={{ marginBottom: 10, color: '#fff' }}>
              Confirmar Senha
            </InputLabel>
            <TextField
              id="confirmPassword"
              autoComplete="off"
              variant='outlined'
              className={classes.textField}
              value={this.state.confirmPassword}
              onChange={this.handleChange('confirmPassword')}
              type="password"
              error={!!(confirmPassword.length !== 0 && confirmPassword !== password)}
              helperText={confirmPassword !== password ? 'As senhas precisam ser iguais.' : null}
            />
            {error ? (
              <p>
                <strong style={{ color: '#f2545b', fontSize: 16 }}>
                  Erro ao redefinir a senha, verifique seus dados e tente novamente.
                </strong>
              </p>
            ) : null}
          </div>

          <div style={{ textAlign: 'center', width: 300 }}>
            {!isVerified ? (
              <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                render="explicit"
                onloadCallback={() => {}}
                verifyCallback={this.verifyCaptcha}
                hl="pt-BR"
              />
            ) : (
              <React.Fragment>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleSubmit}
                  disabled={!!loading}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{
                        width: 25,
                        height: 25,
                      }}
                    />
                  ) : (
                    'Confirmar'
                  )}
                </Button>
              </React.Fragment>
            )}
            <Typography style={{ color: '#fff'}}>
              Entrar com outra conta.
              {' '}
              <Link to="/" className={classes.link} style={{ color: '#b9e600'}}>
                Fazer Login
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authsReducer: state.auth,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      successLogin,
      failChangePassword,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Index))
